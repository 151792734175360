import React, { useEffect, useState } from 'react';
import { ReactComponent as LoadingSpinner } from 'images/loader.svg';
import { useSelector } from 'react-redux';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useQueryParams } from 'hooks/useQueryParam';
import { authSessionThunk, getLoanOffer, getStudentLoanAssistanceData } from 'thunks';
import { trackConversionLead } from 'utils/analytics';
import { getUtmTagsVariables } from 'utils/getUtmTags';
import StateContainer from 'components/StateContainer';
import { getYourContactData } from 'selectors/getYourContact';
import { getYourNameData } from 'selectors/yourName';
import { getMethodAuthData } from 'selectors/methodAuth';
import { FlowComponentType } from 'routes/FlowRouter';
import { useNavigationType } from 'hooks/useNavigate';
import { getAuthData } from 'selectors/getAuthData';
import { getYourIncome } from 'selectors/yourIncome';
import { getProfessionGroup } from 'selectors/professionGroup';
import { CurrentFlow } from 'enums/CurrentFlow';
import { LoanOfferProgress, LoanOfferStep } from 'api/LoanOfferApi';
import { StudentLoanEligibilityResult as Result } from 'enums/StudentLoanForgivenessFlowResults';
import { getStudentLoanData } from 'selectors/getStudentLoanData';

import styles from './Analyzing.module.scss';

const STEPS_READY_FOR_STUDENT_LOANS = [
  LoanOfferStep.RunningDecisionEngine,
  LoanOfferStep.GeneratingDebtProfile,
  LoanOfferStep.CreatingHardOffer,
];

type StudentLoanAssistanceInputData = {
  ready: boolean;
  applicationId?: string;
};

const Analyzing = ({ handleNext }: FlowComponentType): JSX.Element => {
  const params = useQueryParams();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const navigationType = useNavigationType();

  const { email, phone_number: phoneNumber } = useSelector(getYourContactData);
  const { first_name: firstName, last_name: lastName } = useSelector(getYourNameData);
  const {
    total_annual_income: totalAnnualIncome,
    start_of_employment: startOfEmployment,
    employer_name: employerName,
  } = useSelector(getYourIncome);
  const { professionGroup } = useSelector(getProfessionGroup);
  const { entityId, isLoading } = useSelector(getMethodAuthData);
  const { sessionToken } = useSelector(getAuthData);
  const { isLoading: isLoadingStudentLoanData, fetched: studentLoanDataFetched, eligible } = useSelector(
    getStudentLoanData,
  );
  const [studentLoanAssitanceInput, setStudentLoanAssitanceInput] = useState<StudentLoanAssistanceInputData>({
    ready: false,
  });

  // Create financial checkup application
  useEffect(() => {
    const apply = async () => {
      if (!entityId && isLoading) {
        return;
      }
      if (!entityId && !isLoading) {
        if (!sessionToken) {
          handleNext(Result.Error);
          return;
        }
        dispatchWithUnwrap(
          authSessionThunk({
            phoneNumber,
            firstName,
            lastName,
            sessionToken,
          }),
        );
        return;
      }

      try {
        const offer = await dispatchWithUnwrap(
          getLoanOffer({
            application: {
              firstName,
              lastName,
              professionGroup,
              email,
              phoneNumber,
              entityId,
              totalAnnualIncome: totalAnnualIncome ?? 0,
              resumeLink: `${window.location.href}`,
            },
            employment: [
              {
                borrower_employer_name: employerName,
                hire_datetime: startOfEmployment!,
              },
            ],
            // StudentLoanForgiveness uses FinancialCheckup flow
            currentFlow: CurrentFlow.FinancialCheckup,
            utm: getUtmTagsVariables(params),
            sessionToken,
            updateCallback: loanOfferUpdateCallback,
          }),
        );

        setStudentLoanAssitanceInput({ ready: true, applicationId: offer.data.application_id });
      } catch (error) {
        handleNext(Result.Error);
        return;
      }

      trackConversionLead({
        email,
        firstName,
        lastName,
        phoneNumber,
      });
    };

    if (navigationType === 'POP') {
      return;
    }
    apply();
  }, [entityId]);

  // When creating a loan offer, we intercept the update callback to determine if application is ready to get student loan assistance
  const loanOfferUpdateCallback = async (data: LoanOfferProgress) => {
    if (data.applicationId && STEPS_READY_FOR_STUDENT_LOANS.includes(data.step)) {
      setStudentLoanAssitanceInput({ ready: true, applicationId: data.applicationId });
    }
  };

  // Fetch student loan assistance data for application
  const fetchStudentLoanAssistanceData = async () => {
    if (studentLoanAssitanceInput.ready && studentLoanAssitanceInput.applicationId) {
      const { applicationId } = studentLoanAssitanceInput;
      await dispatchWithUnwrap(getStudentLoanAssistanceData({ applicationId }));
    }
  };

  // Fetch student loan assistance data for application when application data is available
  useEffect(() => {
    if (!isLoadingStudentLoanData && !studentLoanDataFetched && studentLoanAssitanceInput.ready) {
      fetchStudentLoanAssistanceData();
    }
  }, [studentLoanAssitanceInput]);

  useEffect(() => {
    if (eligible !== undefined) {
      handleNext(eligible ? Result.Eligible : Result.NotEligible);
    }
  }, [eligible]);

  return (
    <div className={styles.container}>
      <StateContainer icon={<LoadingSpinner />} title="Analyzing Student Loans..." />
    </div>
  );
};

export default Analyzing;
